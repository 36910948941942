import { useEffect } from 'react'

const useScrollIntoView = (ref, offset) => {
  useEffect(() => {
    if (typeof window === 'undefined' || !ref.current) return
    window.scrollTo(0, ref.current.getBoundingClientRect().top + (offset || 0))
  }, [ref, offset])
}

export default useScrollIntoView
